import instance from "@/axios"

/** 获取消息模版列表 */
export const getScanVersionFeedbackListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanUserFeedback/list',
        method: 'GET',
        params: params
    })
}

/** 提交编辑--内容 */
export const postHandleStatusOrHandleRemarkApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanUserFeedback/handle',
        method: 'post',
        data: params
    })
}