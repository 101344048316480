
/** 对版反馈列表 */
export const scanVersionFeedbackTableColumns = [
    { title: "反馈用户", align: "center", scopedSlots: {customRender: 'itemScanVersionInfoSlot'}, width: 150 },
    { title: "大币种", align: "center", scopedSlots: {customRender: 'itemBigCoinSlot'}, width: 120 },
    { title: "处理状态", align: "center", scopedSlots: {customRender: 'itemHandleStatusSlot'}, width: 120 },
    { title: "内部备注", align: "center", scopedSlots: {customRender: 'itemHandleRemarkSlot'}, width: 120 },
    { title: "反馈数据", align: "center", scopedSlots: {customRender: 'itemScanVersionFeedbackSlot'} },
    { title: "反馈时间", align: "center", dataIndex: 'createTime', width: 180 },
]

/** 数据 */
export const handleStatusList = [
    { name: '已处理', value: 1 },
    { name: '未处理', value: 0 },
]