var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemScanVersionInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-black font-weight-bold" }, [
                  _vm._v(_vm._s(row.userName) + "【"),
                  _c("span", { staticClass: "color-orange" }, [
                    _vm._v(_vm._s(row.userId))
                  ]),
                  _vm._v("】")
                ])
              ])
            }
          },
          {
            key: "itemBigCoinSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.coinName
                  ? _c("div", { staticClass: "color-black font-weight-bold" }, [
                      _vm._v("【"),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(row.coinName))
                      ]),
                      _vm._v("】")
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemHandleStatusSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "div",
                    { class: row.handleStatus ? "color-green" : "color-red" },
                    [_vm._v(_vm._s(row.handleStatus ? "已处理" : "未处理"))]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定已处理？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleConfirmHandleStatus(row)
                        }
                      }
                    },
                    [
                      !row.handleStatus
                        ? _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { type: "primary" }
                            },
                            [_vm._v("已处理")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemHandleRemarkSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c("div", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.handleRemark))
                  ]),
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-10",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleActionStatusRemark(row, "remark")
                        }
                      }
                    },
                    [_vm._v("内部备注")]
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemScanVersionFeedbackSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "flex-colum-start-center" }, [
                  _c("div", { staticClass: "mt-20 color-green inline-block" }, [
                    _vm._v("反馈ID：" + _vm._s(row.id))
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mt-20 color-blue cur-pot inline-block",
                      on: {
                        click: function($event) {
                          return _vm.handleGoToScanVersionListPage(row.duibanId)
                        }
                      }
                    },
                    [_vm._v("对版ID：" + _vm._s(row.duibanId))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-20 color-black font-weight-bold inline-block font-size-16 cur-pot",
                      on: {
                        click: function($event) {
                          return _vm.handleGoToScanVersionListPage(row.duibanId)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.feedbackContent))]
                  )
                ])
              ])
            }
          }
        ])
      }),
      _c("EditorStatusOrRemarkPopup", {
        ref: "editorStatusOrRemarkPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }