<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   对版相关数据   -->
      <div slot="itemScanVersionInfoSlot" slot-scope="row">
        <div class="color-black font-weight-bold">{{ row.userName }}【<span class="color-orange">{{ row.userId }}</span>】</div>
      </div>
<!--   反馈大币种   -->
      <div slot="itemBigCoinSlot" slot-scope="row">
        <div class="color-black font-weight-bold" v-if="row.coinName">【<span class="color-green">{{ row.coinName }}</span>】</div>
      </div>
<!--   反馈处理状态   -->
      <div slot="itemHandleStatusSlot" slot-scope="row">
        <div :class="row.handleStatus ? 'color-green' : 'color-red'">{{ row.handleStatus ? '已处理' : '未处理' }}</div>
        <a-popconfirm
            title="确定已处理？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleConfirmHandleStatus(row)"
        >
          <a-button class="mt-10" v-if="!row.handleStatus" type="primary">已处理</a-button>
        </a-popconfirm>
      </div>
<!--  反馈备注   -->
      <div slot="itemHandleRemarkSlot" slot-scope="row">
        <div class="color-black">{{ row.handleRemark }}</div>
        <a-button class="mt-10" type="primary" @click="handleActionStatusRemark(row, 'remark')">内部备注</a-button>
      </div>
<!--   对版反馈相关数据   -->
      <div slot="itemScanVersionFeedbackSlot" slot-scope="row">
        <div class="flex-colum-start-center">
          <div class="mt-20 color-green inline-block">反馈ID：{{ row.id }}</div>
          <div class="mt-20 color-blue cur-pot inline-block" @click="handleGoToScanVersionListPage(row.duibanId)">对版ID：{{ row.duibanId }}</div>
          <div class="mt-20 color-black font-weight-bold inline-block font-size-16 cur-pot" @click="handleGoToScanVersionListPage(row.duibanId)">{{ row.feedbackContent }}</div>
        </div>
      </div>
    </a-table>
    <EditorStatusOrRemarkPopup ref="editorStatusOrRemarkPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {goToOtherPage} from "@/untils/otherEvent"
import {scanVersionFeedbackTableColumns} from "@/views/cmsPage/versionManage/duibanFeedback/_data"
import EditorStatusOrRemarkPopup
  from "@/views/cmsPage/versionManage/duibanFeedback/_components/EditorStatusOrRemarkPopup/index.vue"
import {postHandleStatusOrHandleRemarkApi} from "@/views/cmsPage/versionManage/duibanFeedback/_apis"

export default {
  components: {EditorStatusOrRemarkPopup},
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: scanVersionFeedbackTableColumns,
    }
  },

  methods: {
    /** 确定已处理 */
    async handleConfirmHandleStatus(row) {
      const res = await postHandleStatusOrHandleRemarkApi({
        id: row.id,
        handleStatus: 1
      })
      if (res.status !== '200') return
      this.handleSuccess()
    },
    /** 去往操作 */
    handleActionStatusRemark(row, type) {
      this.$refs.editorStatusOrRemarkPopupEl.show(row, type)
    },
    /** 去往链接页面 */
    handleGoToScanVersionListPage(recordId) {
      goToOtherPage(`/cms/dqmanage/duibanrecord?recordId=${recordId}`)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>