<template>
  <a-modal
      v-model="showPopup"
      :title="titleText"
      :width="500"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item v-if="type === 'status'" label="是否已处理">
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              showSearch
              class="w-200 ml-5"
              v-model="params.handleStatus"
              placeholder="处理状态"
          >
            <a-select-option
                v-for="item of handleStatusList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="type === 'remark'" label="内部备注">
          <a-input
              v-model="params.handleRemark"
              class="w-400"
              type="textarea"
              :auto-size="{ minRows: 6, maxRows: 10 }"
              placeholder="备注"
          />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {handleStatusList} from "@/views/cmsPage/versionManage/duibanFeedback/_data"
import {postHandleStatusOrHandleRemarkApi} from "@/views/cmsPage/versionManage/duibanFeedback/_apis"

export default {
  props: ['rateType'],
  data() {
    return {
      showPopup: false,
      handleStatusList: handleStatusList,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      type: '',
      titleText: '',
      titleTypeMapText: {
        status: '编辑处理状态',
        remark: '编辑内部备注'
      },
      params: {
        id: undefined,
        handleStatus: undefined,
        handleRemark: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data, type) {
      this.type = type
      this.titleText = this.titleTypeMapText[type]
      this.params = updateBaseObjectWithComplex(this.params, data)
      this.showPopup = true
    },
    /** 确定 */
    async handleConfirm() {
      if (this.type === 'status') {
        delete this.params.handleRemark
      }
      if (this.type === 'remark') {
        delete this.params.handleStatus
      }
      const res = await postHandleStatusOrHandleRemarkApi(this.params)
      if (res.status !== '200') return
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        handleStatus: undefined,
        handleRemark: undefined,
      }
      this.showPopup = false
    },
  }
}
</script>