var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.titleText,
        width: 500,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _vm.type === "status"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "是否已处理" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticClass: "w-200 ml-5",
                          attrs: {
                            dropdownMatchSelectWidth: false,
                            allowClear: "",
                            showSearch: "",
                            placeholder: "处理状态"
                          },
                          model: {
                            value: _vm.params.handleStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "handleStatus", $$v)
                            },
                            expression: "params.handleStatus"
                          }
                        },
                        _vm._l(_vm.handleStatusList, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === "remark"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "内部备注" } },
                    [
                      _c("a-input", {
                        staticClass: "w-400",
                        attrs: {
                          type: "textarea",
                          "auto-size": { minRows: 6, maxRows: 10 },
                          placeholder: "备注"
                        },
                        model: {
                          value: _vm.params.handleRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "handleRemark", $$v)
                          },
                          expression: "params.handleRemark"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }